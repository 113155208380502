<template>
    <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="slide-y-transition"
        max-height="200"
        max-width="200"
        offset-y
        background-color="white"
        :disabled="disabled"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :label="fieldLabel"
                :value="time"
                readonly
                v-bind="attrs"
                v-on="on"
                :dense="dense"
                @click:append="menu1 = true"
                :error-messages="errorMessages"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <div class="d-flex white">
            <v-list>
                <v-list-item-group v-model="hhIndex" color="primary">
                    <v-subheader>HH</v-subheader>
                    <v-list-item v-for="i in hours" :key="i">{{
                        i
                    }}</v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list>
                <v-list-item-group v-model="mmIndex" color="primary">
                    <v-subheader>MM</v-subheader>
                    <v-list-item v-for="i in minutes" :key="i">{{
                        i
                    }}</v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
    </v-menu>
</template>

<script>
import { map, findIndex } from 'lodash'

export default {
    props: {
        fieldLabel: null,
        value: {
            type: String,
            default: '00:00'
        },
        errorMessages: {
            type: [String, Array],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            menu1: false,
            hhIndex: 8,
            mmIndex: 0
        }
    },

    created() {
        this.setValues()
    },

    methods: {
        setValues() {
            let val = '00:00'
            if (this.value) {
                val = this.value
            }

            const hhmm = String(val).split(':', 2)
            this.hhIndex = findIndex(this.hours, v => v === hhmm[0])
            const mmIndex = findIndex(this.minutes, v => v === hhmm[1])
            this.mmIndex = mmIndex < 0 ? 0 : mmIndex
        }
    },

    computed: {
        time() {
            return `${this.hours[this.hhIndex]}:${this.minutes[this.mmIndex]}`
        },
        hours() {
            return map(new Array(24), (_, i) => {
                return String(i).padStart(2, '0')
            })
        },
        minutes() {
            return map(new Array(59), (_, i) => {
                return String(i).padStart(2, '0')
            })
        }
    },

    watch: {
        value(val) {
            this.setValues()
        },
        time(val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style></style>
