<template>
    <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="slide-y-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        offset-overflow
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :label="fieldLabel"
                :value="dateFormatted"
                readonly
                v-bind="attrs"
                v-on="on"
                :dense="dense"
                @click:append="menu1 = true"
                :error-messages="errorMessages"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
            @change="event => $emit('change', event)"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import dayjs from 'dayjs'

const dateDisplayFormat = 'YYYY-MM-DD'
const dateValueFormat = 'YYYY-MM-DD'

export default {
    props: {
        fieldLabel: null,
        value: {
            type: [String, Date],
            default: () => dayjs().format(dateValueFormat)
        },
        errorMessages: {
            type: [String, Array],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            date: this.value,
            menu1: false
        }
    },

    computed: {
        dateFormatted: {
            // getter
            get: function() {
                return this.formatDate(this.date)
            },
            // setter
            set: function(newValue) {
                this.date = dayjs(newValue).format(dateValueFormat)
            }
        }
    },

    watch: {
        date(val) {
            this.dateFormatted = this.formatDate(val)
            this.$emit('input', this.date)
        },
        value(val) {
            this.date = dayjs(val).format(dateValueFormat)
        }
    },

    methods: {
        formatDate(date) {
            return dayjs(date).format(dateDisplayFormat)
        }
    }
}
</script>

<style></style>
