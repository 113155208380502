<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />

            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-blod"
                >Articles</v-toolbar-title
            >
            <v-spacer></v-spacer>

            <v-row v-if="showSearch" align="center" style="max-width: 300px">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search title, tags or date"
                    v-model="filter.search"
                    @input="search"
                >
                </v-text-field>
            </v-row>
            <!-- search box loader -->
            <v-skeleton-loader
                type="actions"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>
            <!-- search box -->
            <template v-else>
                <v-btn class="ml-5" icon @click="showSearch = !showSearch">
                    <img src="@/assets/icons/search.svg" alt height="17.7px" />
                </v-btn>
            </template>
        </v-app-bar>

        <div
            class="mt-12 mx-4"
            id="article-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
        >
            <v-col class="text-right">
                <v-btn
                    class="mx-2"
                    right
                    fab
                    dark
                    color="primary"
                    to="/interests/articles/create"
                >
                    <v-icon dark> {{ icons.plus }} </v-icon>
                </v-btn>
            </v-col>
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">
                            Id
                            <v-icon black small @click="sort('id')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                        <th class="text-left">
                            Title
                            <v-icon black small @click="sort('title')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                        <th class="text-left">
                            Category
                            <v-icon black small @click="sort('category.title')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                        <th class="text-left">
                            Type
                            <v-icon black small @click="sort('type')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                        <th class="text-left">
                            Status
                            <v-icon black small @click="sort('isdraft')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                        <th class="text-left">
                            Date
                            <v-icon black small @click="sort('date')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                        <th class="text-left">
                            Created at
                            <v-icon black small @click="sort('created_at')">
                                {{ icons.sort }}
                            </v-icon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2" v-if="unAuthorizedMessage != ''">
                            <div class="display-1 my-6">
                                {{ unAuthorizedMessage }}
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="article in articles"
                        :key="article.id"
                        class="clickable"
                        @click="
                            $router.push({
                                name: 'article.details',
                                params: { id: article.id }
                            })
                        "
                    >
                        <td class="py-5">{{ article.id }}</td>
                        <td class="py-5">{{ article.title }}</td>
                        <td class="py-5">{{ article.category.title }}</td>
                        <td class="py-5">{{ article.type }}</td>
                        <td class="py-5">
                            {{ article.isdraft ? 'Draft' : 'Published' }}
                        </td>
                        <td class="py-5">{{ article.articleDate }}</td>
                        <td class="py-5">{{ article.createdDate }}</td>
                    </tr>

                    <template v-if="loading">
                        <tr v-for="index in 10" :key="index + '-skeleton'">
                            <td class="py-3 pl-0" width="5%">
                                <v-skeleton-loader
                                    class="avatar-loading--36"
                                    type="avatar"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </div>
    </div>
</template>
<script>
import debounce from 'lodash/debounce'
import { mapActions, mapMutations, mapState } from 'vuex'

import {
    mdiDotsVertical,
    mdiUnfoldMoreHorizontal,
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiChevronRight,
    mdiFileExcel,
    mdiPlus,
    mdiSort
} from '@mdi/js'

import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'

export default {
    name: 'ArticlePage',
    directives: {
        infiniteScroll
    },
    mixins: [ControlsMixin],
    components: {
        AppBarNavIcon
    },
    data() {
        return {
            unAuthorizedMessage: '',
            loading: false,
            showSearch: false,
            filter: {
                search: ''
            },
            sortBy: 'desc',
            icons: {
                settings: mdiDotsVertical,
                soft: mdiUnfoldMoreHorizontal,
                search: mdiMagnify,
                view: mdiAccountCircleOutline,
                arrow: mdiChevronRight,
                export: mdiFileExcel,
                plus: mdiPlus,
                sort: mdiSort
            }
        }
    },

    methods: {
        ...mapMutations({
            clearArticleList: 'article/clearArticleList'
        }),
        ...mapActions({
            getArticles: 'article/getArticles'
        }),
        search: debounce(function() {
            this.clearArticleList()
            this.fetchArticles()
        }, 600),

        fetchArticles(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            this.getArticles(params)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 403) {
                        this.unAuthorizedMessage = err.response.data.message
                    }
                })
        },

        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchArticles(this.listMeta.current_page + 1)
            }
        },
        sort(attrib) {
            let attribs = attrib.split('.')
            if (this.sortBy === 'desc') {
                this.articles.sort((a, b) => {
                    if (attribs.length === 1) {
                        return a[attrib] > b[attrib] ? -1 : 1
                    }
                    return a[attribs[0]][attribs[1]] > b[attribs[0]][attribs[1]]
                        ? -1
                        : 1
                })

                this.sortBy = 'asc'
            } else {
                this.articles.reverse()
                this.sortBy = 'desc'
            }
        }
    },
    computed: {
        ...mapState({
            articles: state => state.article.list,
            listMeta: state => state.article.listMeta
        })
    },
    created() {
        this.clearArticleList()
        this.fetchArticles(1)
        this.sort('id')
    }
}
</script>
<style lang=""></style>
