<style>
#input-166,
#input-169 {
    width: 160px;
}
</style>
<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-toolbar-title class="headline font-weight-bold">
                <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
                <router-link
                    class="text-decoration-none"
                    :to="{ name: 'articles' }"
                >
                    Articles
                </router-link>
            </v-toolbar-title>
        </v-app-bar>
        <v-form ref="form" lazy-validation v-if="!loading">
            <v-container fluid>
                <v-card
                    class="mx-auto px-5"
                    tile
                    outlined
                    elevation="1"
                    width="100%"
                >
                    <v-row>
                        <v-col>
                            <v-text-field
                                :error-messages="form.$getError('title')"
                                v-model="form.title"
                                label="Title"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" sm="12" md="8" lg="8">
                            <v-row>
                                <v-col cols="12" sm="12" md="4" lg="4">
                                    <v-select
                                        :error-messages="
                                            form.$getError('category_id')
                                        "
                                        v-model="form.category_id"
                                        :items="categories"
                                        item-text="title"
                                        item-value="id"
                                        label="Interest Category"
                                    ></v-select>
                                </v-col>
                                <!-- Publish Date -->
                                <v-col cols="12" sm="12" md="4" lg="4">
                                    <date-picker
                                        fieldLabel="Publish Date"
                                        v-model="form.date"
                                        outlined
                                        type="date"
                                        :error-messages="form.$getError('date')"
                                        @change="form.$clearError('date')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="12" md="4" lg="4">
                                    <v-select
                                        v-model="form.type"
                                        :error-messages="form.$getError('type')"
                                        :items="article_types"
                                        label="Article Type"
                                    ></v-select>
                                </v-col>
                                <v-col sm="12" md="2" lg="2">
                                    <date-picker
                                        fieldLabel="Start Date"
                                        v-if="form.type === 'Events'"
                                        v-model="form.start_date"
                                        outlined
                                        type="date"
                                        :error-messages="
                                            form.$getError('start_date')
                                        "
                                        @change="form.$clearError('start_date')"
                                    />
                                </v-col>
                                <v-col sm="12" md="2" lg="2">
                                    <date-picker
                                        fieldLabel="End Date"
                                        v-if="form.type === 'Events'"
                                        v-model="form.end_date"
                                        outlined
                                        type="date"
                                        :error-messages="
                                            form.$getError('end_date')
                                        "
                                        @change="form.$clearError('end_date')"
                                    />
                                </v-col>
                                <v-col sm="6" md="2" lg="2">
                                    <time-picker
                                        v-if="form.type === 'Events'"
                                        fieldLabel="Start Time"
                                        v-model="form.time_start"
                                        type="date"
                                        outlined
                                        :error-messages="
                                            form.$getError('start_time')
                                        "
                                        @input="form.$clearError('start_time')"
                                    />
                                </v-col>
                                <v-col sm="6" md="2" lg="2">
                                    <time-picker
                                        v-if="form.type === 'Events'"
                                        fieldLabel="End Time"
                                        v-model="form.time_end"
                                        type="date"
                                        outlined
                                        :error-messages="
                                            form.$getError('end_time')
                                        "
                                        @input="form.$clearError('end_time')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.summary"
                                        label="Summary"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" sm="12" md="4" lg="4">
                            <v-img
                                id="previewImage"
                                v-model="form.image"
                                max-height="300"
                                max-width="480"
                                class="elevation-3"
                                :src="imageSrc"
                            ></v-img>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-combobox
                                v-model="form.tags"
                                :items="tags"
                                item-text="name"
                                label="Tags"
                                append-icon
                                multiple
                                hide-selected
                                chips
                                deletable-chips
                                class="tag-input"
                                :search-input.sync="search"
                                @keyup.tab="updateTags"
                                @paste="updateTags"
                                placeholder="Write tags and use Tab to separate each tags."
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom:10px;">
                        <v-col cols="12" sm="12" md="12">
                            <!-- Use the component in the right place of the template -->
                            <tiptap-vuetify
                                v-model="form.contents"
                                :extensions="extensions"
                                output-format="html"
                                placeholder="Start writing your article here..."
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </v-container>
            <v-btn
                class="ma-2 float-right"
                style="width:150px;"
                color="secondary"
                :loading="isDrafting"
                @click="submitDraft"
                >Draft</v-btn
            >
            <v-btn
                class="ma-2 float-right"
                style="width:150px;"
                color="primary"
                :loading="isPublishing"
                @click="submitForm"
                >Publish</v-btn
            >
            <v-btn
                class="ma-2 pull-right"
                style="width:120px;"
                color="error"
                @click="deleteArticle"
                >Delete</v-btn
            >
            <v-btn
                class="ma-2 pull-left "
                style="width:120px;"
                color="normal"
                :loading="isSelecting"
                @click="uploadImage"
                >Upload Image
            </v-btn>
            <input
                :v-model="form.image"
                ref="uploader"
                type="file"
                class="d-none"
                accept="image/*"
                @change="onFileChanged"
            />
        </v-form>

        <v-overlay absolute :value="loading" opacity="0.2">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'
import DatePicker from '@/components/form-fields/date-picker'
import TimePicker from '@/components/form-fields/time-picker'
import { mapActions, mapState } from 'vuex'
import { mdiChevronLeft } from '@mdi/js'
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from 'tiptap-vuetify'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import moment from 'moment'

dayjs.extend(utc)

const initialData = {
    title: null,
    category_id: null,
    type: null,
    image: null,
    article_type: null,
    date: new Date().toISOString().substr(0, 10),
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
    time_start: '00:00',
    time_end: '00:00',
    summary: null,
    tag: null,
    contents: ''
}

export default {
    name: 'ArticleForm',
    components: {
        AppBarNavIcon,
        TiptapVuetify,
        DatePicker,
        TimePicker
    },
    data() {
        return {
            loading: false,
            page: 'create',
            date: null,
            menu: null,
            dtModal: false,
            form: new Form({
                ...initialData
            }),
            search: '',
            categories: [],
            tags: [],
            article_types: ['News', 'Events'],
            icons: {
                arrowLeft: mdiChevronLeft
            },
            deleteDialog: false,
            snackbar: {
                show: false,
                message: null,
                color: ''
            },
            publish: false,
            draft: false,
            imageSrc: require('@/assets/images/article-image-placeholder.png'),
            imageLoading: false,
            uploading: false,
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3, 4, 5]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            selectedFile: null,
            isSelecting: false,
            isPublishing: false,
            isDrafting: false
        }
    },

    methods: {
        ...mapActions({
            getCategories: 'category/getCategories',
            getTags: 'article/getTags',
            createArticle: 'article/createArticle',
            getArticleDetails: 'article/getArticleDetails',
            updateArticle: 'article/updateArticle'
        }),
        // update tags
        updateTags() {
            this.$nextTick(() => {
                this.form.tag.push(...this.search.split(','))
                this.$nextTick(() => {
                    this.search = ''
                })
            })
        },

        // submit form
        async submitForm() {
            this.publish = true
            this.draft = false
            this.isPublishing = true
            this.saveArticle()
        },

        // submit draft
        async submitDraft() {
            this.draft = true
            this.publish = false
            this.isDrafting = true
            this.saveArticle()
        },

        // save article
        saveArticle() {
            let action = null
            let data = new FormData()
            let combineStart = this.combineDateTime(
                this.form.start_date,
                this.form.time_start
            )
            let combineEnd = this.combineDateTime(
                this.form.end_date,
                this.form.time_end
            )

            data.append('title', this.form.title)
            data.append('category_id', this.form.category_id)
            data.append('type', this.form.type)
            data.append('date', this.form.date)
            data.append('start_date', combineStart)
            data.append('end_date', combineEnd)
            data.append('summary', this.form.summary)
            data.append('tag', this.form.tags)
            data.append('image', this.form.image)
            data.append('contents', this.form.contents)
            data.append('ispublished', this.publish)
            data.append('isdraft', this.draft)

            if (this.page === 'create') {
                action = 'createArticle'
            } else {
                action = 'updateArticle'
                data.append('id', this.form.id)
            }

            this[action](data)
                .then(() => {
                    this.form.$busy = false
                    this.$router.replace('/interests/articles')
                })
                .catch(err => {
                    this.isDrafting = false
                    this.isPublishing = false
                    if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    } else {
                        this.showSnackbar(err.response.data.message, 'error')
                    }
                    this.form.$busy = false
                })
        },

        // combine date to datetime
        combineDateTime(initialdate, timevalue) {
            let datetimeA = moment(initialdate + ' ' + timevalue).format()
            return datetimeA
        },

        uploadImage() {
            this.isSelecting = true
            window.addEventListener(
                'focus',
                () => {
                    this.isSelecting = false
                },
                { once: true }
            )
            this.$refs.uploader.click()
        },
        onFileChanged(e) {
            const file = e.target.files[0]

            if (file && this.isImage(file)) {
                this.form.image = file
                const reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                reader.onload = e => {
                    this.imageSrc = e.target.result
                }
            }
        },

        isImage(file) {
            return file && file['type'].split('/')[0] === 'image'
        },

        deleteArticle() {
            this.form.$busy = true
            this.article
                .delete()
                .then(() => {
                    this.form.$busy = false
                    this.deleteDialog = false
                    this.$router.replace('/interests/articles')
                })
                .catch(err => {
                    this.deleteDialog = false
                    this.showSnackbar(err.response.data.message, 'error')
                    this.form.$busy = false
                })
        },

        setDefaultDateAndTime() {
            if (!this.form.start_date) {
                const date = dayjs()
                    .hour(8)
                    .minute(0)
                this.form.start_date = date.format('YYYY-MM-DD')
            }

            if (!this.form.end_date) {
                const date = dayjs()
                this.form.end_date = date.format('YYYY-MM-DD')
            }
        },

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        }
    },

    computed: {
        ...mapState({
            article: state => {
                return state.article.articleDetails
            }
        })
    },
    watch: {
        article(newValue, oldValue) {
            this.form = new Form(newValue)
            if (this.form.image !== null) {
                this.imageSrc = this.form.image.url
            }
        }
    },
    created() {
        if (this.$route.params.id) {
            this.page = 'edit'
            this.loading = true
            this.getArticleDetails(this.$route.params.id)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        this.showSnackbar(err.response.data.message, 'error')
                    } else {
                        this.showSnackbar(err.response.data.message, 'error')
                    }
                    this.form.$busy = false
                })
        } else {
            this.page = 'create'
        }

        this.getCategories().then(res => {
            this.categories = res.data
        })

        this.getTags().then(res => {
            this.tags = res.data.map(data => data.name)
        })

        this.setDefaultDateAndTime()
    }
}
</script>
